












































































































































































































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { UserStore } from '@/store/user'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'
import { updateProducer } from '#/api/profile'
import { UploadStore, UploadType } from '~/store/upload'
import { ILodashSetParams } from '#/store/user'
import { changePassword } from '~/api/auth'
import { sendEmailPasswordChanged } from '~/api/email'
import { prettifyApiError } from '~/services/prettify-api-error'

enum FileToRemoveOrAdd {
	Logo,
	Background,
	Tag,
	Epk
}

@Component({ components: { VLoadSpinner } })
export default class SettingsGeneralPage extends Vue {
	passwordChangeParams: IResetPasswordChangeParams = { old_password: '', new_password1: '', new_password2: '' }
	passwordChangeError = ''
	FileToRemoveOrAdd = FileToRemoveOrAdd
	regexUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

	@ModalStore.State('isLoading') isLoading: boolean
	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer

	get userTagUrl() {
		return `https://s3.amazonaws.com/clickandrap-djangovue/${this.authenticatedUser.tag_file}`
	}

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
	@UserStore.Mutation('UPDATE_PRODUCER_PROPERTY_LOCALLY') UPDATE_PRODUCER_PROPERTY_LOCALLY: (ILodashSetParams: ILodashSetParams) => void
	@UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

	async uploadFile(fileList: FileList, fileToAdd: FileToRemoveOrAdd) {
		const producerToUpdate: Partial<IUserProducer> = { id: this.authenticatedUser.id }

		try {
			const { url, pathToFile } = await this.singleFileUpload({ fileList, uploadType: UploadType.Public })
			switch (fileToAdd) {
				case FileToRemoveOrAdd.Logo:
					producerToUpdate.image_logo_url = url
					this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'image_logo_url', value: url })
					break
				case FileToRemoveOrAdd.Background:
					producerToUpdate.image_background_url = url
					this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'image_background_url', value: url })
					break
				case FileToRemoveOrAdd.Tag:
					producerToUpdate.tag_file = pathToFile
					this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'tag_file', value: pathToFile })
					break
				case FileToRemoveOrAdd.Epk:
					producerToUpdate.about_epk_url = url
					this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'about_epk_url', value: url })
					break
			}
			await updateProducer(producerToUpdate)
		} catch (err) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		}
	}

	removeFile(fileToRemove: FileToRemoveOrAdd) {
		const producerToUpdate: Partial<IUserProducer> = { id: this.authenticatedUser.id }

		switch (fileToRemove) {
			case FileToRemoveOrAdd.Logo:
				producerToUpdate.image_logo_url = null
				this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'image_logo_url', value: null })
				break
			case FileToRemoveOrAdd.Background:
				producerToUpdate.image_background_url = null
				this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'image_background_url', value: null })
				break
			case FileToRemoveOrAdd.Tag:
				producerToUpdate.tag_file = null
				this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'tag_file', value: null })
				break
			case FileToRemoveOrAdd.Epk:
				producerToUpdate.about_epk_url = null
				this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'about_epk_url', value: null })
				break
		}

		updateProducer(producerToUpdate)
	}

	updateDemoDownloadAllowed(demoDownloadAllowed: boolean) {
		updateProducer({ id: this.authenticatedUser.id, demo_download_allowed: demoDownloadAllowed })
	}

	cleanInputs() {
		this.passwordChangeParams = { old_password: '', new_password1: '', new_password2: '' }
		// this.$validator.reset()
	}

	onUpdateSuccessful() {
		this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
		this.cleanInputs()
	}

	async changePassword() {
		try {
			await changePassword(this.passwordChangeParams)
			this.onUpdateSuccessful()
			sendEmailPasswordChanged()
		} catch (error) {
			this.passwordChangeError = prettifyApiError(error)
		}
	}

	async updateUserFields() {
		const dispatchParams = { ...this.authenticatedUser }
		// remove fields we can't update from this form
		const { license_options, number_of_followers, country, paypal_email, coproducers, ...cleanedUser } = dispatchParams
		await updateProducer(cleanedUser)
		this.onUpdateSuccessful()
	}

	validateForm(formType: string) {
		formType === 'formChangePassword' ? this.changePassword() : this.updateUserFields()
		// this.$validator.validateAll(scope).then(result => {
		//     if (result) scope === 'form-change-password' ? this.changePassword() : this.updateUserFields()
		// })
	}
}
